<template>
  <v-container class="mt-10 px-md-16" fluid>
    <span class="center-x text-h3" v-text="title"/>
    <v-row v-if="branches.length" class="mt-5" dense>
      <v-col v-for="(branch, index) in branches" :key="index" :cols="getColFlex(index)">
        <kurcc-site-card :branch="branch"/>
      </v-col>
    </v-row>
    <v-row v-else class="mt-5" dense>
      <v-col v-for="n in 5" :key="n" :cols="getColFlex(n - 1)">
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
    <v-btn class="mt-5 mb-5" color="primary" @click="$router.push({name: 'search'})">See All</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'KurccSiteCardList',
  components: {
    KurccSiteCard: () => import('@/modules/home/components/KurccSiteCard')
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingBranches: false,
      branches: []
    }
  },
  methods: {
    getColFlex (index) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 12
        case 'sm':
          return 12
        case 'md':
          return 6
        default:
          return this.calculateColFlex(index)
      }
    },
    calculateColFlex (index) {
      if (index === 0 || index === 1) {
        return '6'
      } else {
        return '4'
      }
    },
    getAllBranches () {
      this.loadingBranches = true
      this.$store.dispatch('getAllBranches', {
        perPage: 5,
        currentPage: 1,
        withIncludes: true,
        orderBy: 'created_at',
        sortedBy: 'desc'
      }).then(res => {
        this.branches = res.items
      }).finally(() => {
        this.loadingBranches = false
      })
    }
  },
  created () {
    this.getAllBranches()
  }
}
</script>
